import axios from "axios";
import helper from "./helper";

const postAxios = (endpoint, params) => {
  return axios.post(endpoint, params, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("jwt"),
      "c-time": helper.getDateTime(),
      "x-api-key": helper.sha256(
        process.env.VUE_APP_API_KEY + helper.getDateTime()
      ),
    },
  });
};

export default postAxios;
