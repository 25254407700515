<template>
  <div class="carValuationRecord">
    <h1>Send push notification to all users</h1>
    <v-text-field v-model="message.messageTitle" placeholder="Title" />
    <v-textarea v-model="message.messageInfo" placeholder="Message" />
    <v-spacer></v-spacer>
    <v-btn text color="primary" @click="sendPush()">Submit</v-btn>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import postAxios from "../../../services/axios-post";

Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      message: {
        messageTitle: "",
        messageInfo: "",
      },
      type: null,
      alertMsg: null,
    };
  },
  mounted() {
    this.fetchCredentials();
    this.fetchCarValuationRecord();
  },
  methods: {
    sendPush() {
      const self = this;
      if (self.message.messageTitle !== "" && self.message.messageInfo !== "") {
        postAxios(
          "https://socket.ucars.sg/broadcastMessage",
          self.message
        ).then(res => {
          self.showAlert("success", "Sent successfully : " + res);
        });
      }
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
  },
};
</script>
<style>
.plusButton {
  margin: 50px;
}
</style>
